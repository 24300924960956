body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
* {
  box-sizing: border-box;
}
body {
  height: 100%;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}
body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
}

@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
.navbar-brand {
  font-family: "Abril Fatface", cursive;
}

.abril {
  font-family: "Abril Fatface", cursive !important;
}

.hero-background {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),
    url("photos/dogs.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-color: #2a9d8f;
  min-height: 500px;
}

.hero-text {
  padding-top: 50px;
  font-size: 3.5em;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .hero-background {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),
      url("photos/dogs-mobile.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    background-color: #2a9d8f;
    min-height: 500px;
  }

  .hero-text {
    padding-top: 50px;
    font-size: 3.5em;
  }
}

.pricing-text {
  padding-top: 10px;
  font-size: 3.5em;
  font-weight: 700;
}

.pricing-text-mobile {
  padding-top: 7px;
  font-size: 2em;
  font-weight: 700;
}

.text-shadow {
  text-shadow: 1px 2px 5px #000;
}

.hero-button {
  background: white !important;
}

.hero-button:hover {
  background: #0d6efd !important;
}

.navbar-light {
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
  z-index: 10000;
}
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}
.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #167bff;
}

.beta-text {
  font-size: 10px;
}

.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.rating > input {
  display: none;
}

.rating > label {
  position: relative;
  width: 1em;
  font-size: 30px;
  color: #0275d8;
  cursor: pointer;
}

.rating > label::before {
  content: "\2605";
  position: absolute;
  opacity: 0;
}

.rating > label:hover:before,
.rating > label:hover ~ label:before {
  opacity: 1 !important;
}

.rating > input:checked ~ label:before {
  opacity: 1;
}

.rating:hover > input:checked ~ label:before {
  opacity: 0.4;
}

.grey-background {
  background-color: #F8F9FA !important;
}

.green-background {
  background-color: #229660 !important;
}

a.green-background:hover {
  background-color: rgba(0, 147, 109, 0.8) !important;
}

.blue-background {
  background-color: #118ab2 !important;
}

a.blue-background:hover {
  background-color: rgba(17, 138, 178, 0.8) !important;
}

.red-background {
  background-color: #DE6449 !important;
}

.red-background:hover {
  background-color: rgba(189, 3, 47, 0.8) !important;
}

.red-outline-background {
  border-color: #DE6449 !important;
  color: #DE6449 !important;
}

.red-outline-background:hover {
  background-color: rgba(189, 3, 47, 0.8) !important;
  border-color: rgba(189, 3, 47, 0.8) !important;
  color: white !important;
}

.text-white {
  color: #fff;
}

#startDateInput {
  border-radius: 0.25rem 0 0 0.25rem;
}

#endDateInput {
  border-radius: 0 0.25rem 0.25rem 0;
}


.action-button {
  color: #6c757d !important;
}

.action-button:hover {
  background-color: #eee !important;
}

.transparent-background {
  background-color: #11ffee00 !important;
}

.transparent-background:hover {
  background-color: #F8F9FA !important;
}

.blurry-text {
  color: transparent;
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.nav-pills .nav-link {
  color: #DE6449 !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border: 5px !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #DE6449 !important;
  border: 5px !important;
  border-color: #DE6449 !important;
  color: white !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.horizontal-scroll-right-arrow {
  display: inline-flex !important;
  float:left !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: #fff !important;
  height: 3rem !important;
  width: 3rem !important;
  font-size: 1.9rem !important;
  border-radius: 2rem !important;
  z-index: 100 !important;
  position: absolute !important;
  top: 40% !important;
  right: 1% !important;
  text-decoration: none;
}
.horizontal-scroll-right-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.horizontal-scroll-left-arrow {
  display: inline-flex !important;
  float:left !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: #fff !important;
  height: 3rem !important;
  width: 3rem !important;
  font-size: 1.9rem !important;
  border-radius: 2rem !important;
  z-index: 100 !important;
  position: absolute !important;
  top: 40% !important;
  left: 1% !important;
  text-decoration: none;
}
.horizontal-scroll-left-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.img-card-background {
  width: 100%;
  height: 200px;
  border-style: solid;
  border-width: 0px;
  border-radius: 0px;
  background-clip: padding-box;
  margin: 0 0 0 0;
  float: left;
  background-size: cover;
  background-position: center center;
}

.mobile-store-download-grid {
  gap: 16px;
}

@media (min-width: 768px) { 
  .mobile-store-download-grid {
    gap: 128px;
  }
}

.thread-list {
  flex-basis: 450px !important;
  text-align: left;
  overflow: visible !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: #D8D8D8 !important;
}